.static {
	display: block;
}


.inner-banner {
	background-image: url(RESOURCE/img/Banner-img-6.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 390px;
	position: relative;

	@media (max-width:992px) {
		height: 320px;
	}


	.page-title {
		position: absolute;
		bottom: 70px;
		width: 100%;
		left: 0;
		z-index: 2;
		margin: 0;
		border-bottom: none;

		@media (max-width:992px) {
			bottom: 30px;
		}


		h1 {
			font-size: 42px;
			margin-bottom: 0;
			color: var(--white);
			text-transform: uppercase;

			@media (max-width:992px) {
				font-size: 30px;
			}
		}
	}
}



.teams-bl {
	max-width: 1040px;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.team-bl {
		border: 1px solid #e8e8e8;

		.team-dt {

			border-top: none;
			padding: 20px 8px;
			text-align: center;
		}
	}
}


.contact-page {
	.control-label {
		text-align: left;
		padding-bottom: 5px;
	}

	.form-control {
		border: none;
		border-bottom: 2px solid #f2f2f2;
		margin-bottom: 30px;
		border-radius: 0;
		box-shadow: inherit;
		padding: 0;
	}

	label {
		color: var(--black);
		font-weight: 700;
		font-size: 14px;
		text-transform: uppercase;
		margin-bottom: 0;
		padding-bottom: 5px;
	}

	.checkbox {
		label {
			text-transform: inherit;
			font-weight: normal;
		}

		a {
			color: var(--black);
			font-size: 14px;
		}
	}

	.btn-go {
		box-shadow: 2px 1px 10px rgba(0, 0, 0, .2);
		font-size: 20px;
	}
}

.panel-group {
	width: 100%;

	.panel.panel-default {
		border-color: var(--primary);
		border-radius: 0;
	}

	.panel-heading {
		border-color: var(--primary);
		background-color: var(--primary);
		border-radius: 0;
		padding: 15px 15px;


		.panel-title {
			font-size: 18px;
			margin: 0;
			line-height: 22px;

			a {
				display: flex;
				width: 100%;
				color: #fff;
				text-decoration: none;
				position: relative;
				/* word-break: break-all; */
				padding-left: 40px;

				&:before {
					content: "\f055";
					font-family: Font Awesome\ 5 Pro;
					left: 0;
					top: 0px;
					position: absolute;
				}
			}

			a[aria-expanded=true] {
				&:before {
					content: "\f056";
				}
			}

		}
	}

	.panel-body {
		border-radius: 0;
		border-color: var(--marroncolor);
		padding: 15px 15px 0 15px;

		table {

			th {
				padding: 5px 10px;
				font-weight: bold;
				text-align: center;
				font-size: 15px;

				@media (max-width:992px) {
					display: none;
				}

				&:first-child {
					text-align: left;
				}
			}

			td {
				padding: 5px 10px;
				text-align: left;

				@media (max-width:992px) {
					display: block;
					padding-left: 60%;
					position: relative;

					center {
						text-align: left !important;
					}

					&:before {
						position: absolute;
						left: 15px;
						font-size: 14px;
						width: 50%;
						font-weight: bold;
					}

					&:first-child {
						&:before {}
					}

					&:nth-child(2n) {
						&:before {}
					}

					&:nth-child(3n) {
						&:before {}
					}

					&:nth-child(4n) {
						&:before {}
					}
				}
			}
		}
	}
}